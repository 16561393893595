<template>
  <section>
    <h4>门窗外遮阳卷帘隐私政策</h4>
    <p>生效日期：2025年03月20日</p>
    <p class="title">‌一、我们收集的信息‌</p>
    <p class="subtitle">‌设备与日志信息‌</p>
    <p class="p">1.1、‌设备信息‌：型号、操作系统版本、网络类型（如WiFi/5G），用于适配快应用在手机、平板等终端的显示效果；</p>
    <p class="p">1.2、‌匿名标识符‌：加密处理的设备唯一标识符（如Android ID），用于统计用户活跃度；</p>
    <p class="p">1.3、‌技术日志‌：匿名化IP地址、访问时间、页面跳转路径，用于服务优化与安全防护。</p>
    <p class="title">二、数据使用与共享‌</p>
    <p class="subtitle">数据处理‌</p>
    <p class="p">2.1、数据通过HTTPS加密传输至云服务器存储，日志保留不超过30天；</p>
    <p class="p">2.2、使用华为账号登录时需同意《华为账号用户认证协议》，且服务器部署在中国大陆境内。</p>
    <p class="subtitle">数据共享‌</p>
    <p class="p">2.3、仅与华为生态服务（如应用市场、快应用中心）共享匿名统计数据，用于分发效果分析；</p>
    <p class="p">2.4、不向广告商共享个人数据，法律要求披露时需提前通知用户。</p>
    <p class="title">三、用户权利‌</p>
    <p class="subtitle">权限控制‌</p>
    <p class="p">3.1、地理位置、摄像头等敏感权限默认关闭，使用时需手动授权；</p>
    <p class="p">3.2、通过“设置 > 应用管理 > 快应用中心”管理本地缓存。</p>
    <p class="title">四、未成年人保护‌</p>
    <p class="p">4.1、未满14周岁的用户需监护人同意使用，监护人可通过华为“健康使用手机”功能限制访问权限。</p>
    <p class="title">五、政策更新与联系‌</p>
    <p class="p">5.1、重大变更通过华为应用市场“消息中心”推送通知；</p>
  </section>
</template>
<script>
export default {
  data: () => ({
  }),
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="css">
 section {padding: 10px;}
 h4 {text-align: center; margin: 20px 0;}
 .title {font-weight: 600; margin-bottom: 10px; margin-top: 20px; font-size: 18px;}
 .subtitle {font-weight: 500; margin-bottom: 5px; font-size: 16px;}
 .p {text-indent: 2em; font-size: 14px;}
</style>
